<template>
	<div class="container">
		<yi-list-layout :loading="isLoading">
			<template #title>
				<div class="header-title">抵扣记录</div>
				<div class="header-button">
					<el-button
						:loading="loading"
						@click="onExport"
						v-permission="'pc/finance-center/deduction-record/export'"
					>
						导出Excel
					</el-button>
				</div>
			</template>
			<template #filter>
				<yi-filter ref="filterRef" :setting="settingRef" @change="onChangeFilter" />
			</template>
			<template #table>
				<yi-table
					ref="yiTableRef"
					table-height="100%"
					:columns="columns"
					:data="tableData"
					:params="{ page }"
					showSelection
					autoWidth
				>
					<template #status="{ value }">
						<span>{{ mapDict('trade_deduction_status', value) }}</span>
					</template>
					<template #tradeBillType="{ value }">
						<span>{{ mapDict('trade_bill_type', value) }}</span>
					</template>
					<template #month="{ row }">
						<span>{{ convertDate(row, 'month') }}</span>
					</template>
					<template #statementDate="{ row }">
						<span>{{ convertDate(row) }}</span>
					</template>
					<template #operation="{ row }">
						<span
							class="click"
							v-permission="'pc/finance-center/deduction-record/invalid'"
							@click="onBillDeduction(row)"
						>
							作废
						</span>
					</template>
				</yi-table>
			</template>
			<template #footer>
				<yi-pagination
					:current-page="page.current"
					:page-size="page.size"
					:total-count="totalCount"
					@page-change="onPageChange"
					@size-change="onSizeChange"
				/>
			</template>
		</yi-list-layout>
	</div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'DriverRent',
})
</script>

<script setup name="DriverRent">
import dayjs from 'dayjs'
import { inject, onActivated, ref, getCurrentInstance, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { setting, columns } from './config'
import { useState, useFetch, useDict, useFilter } from '@/utils/hooks.js'
import { companySelect, channelSelect } from '@/api/vehicle.js'
import { deductionList, exportDeduction, invalidDeduction } from '@/api/driver.js'
import { handleExport } from '@/utils/util'
import { ElMessageBox } from 'element-plus'

const $message = inject('$message')

const settingRef = ref(setting)

const [params, setParams] = useState({})
const [totalCount, setTotal] = useState(0)
const [tableData, setTable] = useState([])
const [loading, setLoading] = useState(false)
const { isLoading, fetchData } = useFetch()
const [dict, setDict] = useState({})
const [page, setPage] = useState({ current: 1, size: 20 })

async function fetchList() {
	const payload = { ...params.value, page: page.value }
	const service = deductionList
	const { data, err } = await fetchData(service, payload)
	if (err) {
		$message.error(err.message)
	} else {
		setTable(data.records)
		setTotal(data.total)
	}
}
function mapDict(key, value) {
	const e = dict.value[key]
	if (!e) return '--'
	const target = e.find(item => item.code == value)

	return target ? target.name : '--'
}
function convertDate(val, type) {
	const { statementDate } = val
	if (type === 'month') return dayjs(statementDate).format('YYYY-MM')
	return dayjs(statementDate).format('YYYY-MM-DD')
}

async function onExport() {
	setLoading(true)
	try {
		const res = await exportDeduction(params.value)
		if (res) handleExport(res, '抵扣记录')
	} catch (err) {
		$message.error(err.msg || err.message)
	} finally {
		setLoading(false)
	}
}
function onBillDeduction(row) {
	const { deductionId } = row
	ElMessageBox.confirm('确认作废该条记录?', '提示', {
		confirmButtonText: '确认',
		cancelButtonText: '取消',
		type: 'warning',
	})
		.then(async () => {
			const { err } = await fetchData(invalidDeduction, { deductionId })
			if (err) return $message.error(err.message)
			$message.success('操作成功')
			fetchList()
		})
		.catch(() => {})
}
async function fetchSelect() {
	const [chanRes, compRes] = await Promise.all([
		useFilter(() => channelSelect({ isPermission: true })),
		useFilter(() => companySelect(true), { name: 'companyName', code: 'companyId' }),
	])
	if (!chanRes.err) {
		const channel = findItem('platformChannelId')
		channel.options = chanRes.data
	}
	if (!compRes.err) {
		const company = findItem('companyId')
		company.options = compRes.data
	}
}

function findItem(key) {
	return settingRef.value.filters.find(item => item.attr === key)
}

function onSizeChange(size) {
	setPage({ current: 1, size })
	fetchList()
}
function onPageChange(current) {
	const { size } = page.value
	setPage({ current, size })
	fetchList()
}
function onChangeFilter(event) {
	setParams(event)
	fetchList()
}

async function fetchDict() {
	const dict = await useDict(['trade_deduction_status', 'sex', 'trade_bill_type'])
	if (dict) setDict(dict)
	console.log('dict', dict)
	const status = findItem('status')
	status.options = dict.trade_deduction_status
}
function init() {
	fetchDict()
	fetchList()
	fetchSelect()
	const route = useRoute()
	const query = route.query
	if (Object.keys(query).length > 0) {
		params.value.keyword = query.keyword || ''
		params.value.status = query.status || null
		params.value.companyId = query?.companyIds?.split(',') || []
		const [start, end] = query?.createTime?.split(',') || []
		params.value.createTime =
			(query?.createTime && [`${start} 00:00:00`, `${end ? end : start} 23:59:59`]) || null
		const ins = getCurrentInstance()
		ins.refs.filterRef && ins.refs.filterRef.manualSetParams(params.value)
	}
}
onActivated(() => {
	init()
})
onMounted(() => {
	init()
})
</script>

<style lang="less" scoped>
.container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.header-title {
		height: 54px;
		line-height: 54px;
		overflow: hidden;
		float: left;
	}
	.header-button {
		margin: 11px 0;
		float: right;
	}
}
.click {
	cursor: pointer;
	color: #409eff;
}
</style>
