export const columns = [
	{
		prop: 'carNumber',
		label: '车牌号',
		width: '140px',
	},
	{
		prop: 'driverName',
		label: '司机姓名',
		width: '140px',
	},
	{
		prop: 'platformChannel',
		label: '平台渠道',
		width: '140px',
	},
	{
		prop: 'tradeBillType',
		label: '账单类型',
		width: '140px',
	},
	{
		prop: 'tradeBillName',
		label: '账单名称',
		width: '180px',
	},
	{
		prop: 'tradeBillNo',
		label: '关联账单ID',
		width: '300px',
	},
	{
		prop: 'status',
		label: '费用状态',
		width: '140px',
	},
	{
		prop: 'feeName',
		label: '费用名称',
		width: '180px',
	},
	{
		prop: 'tradeBillStatementDate',
		label: '账单日期',
		width: '180px',
	},
	{
		prop: 'deductionAmount',
		label: '抵扣金额',
		width: '140px',
	},

	{
		prop: 'companyName',
		label: '所属公司',
		width: '280px',
	},

	{
		prop: 'createTime',
		label: '创建时间',
		width: '180px',
	},

	{
		prop: 'operation',
		label: '操作',
		width: '140px',
		fixed: 'right',
	},
]
export const setting = {
	expand: true,
	filters: [
		{
			placeholder: '输入车型名称、车牌、自编号、司机姓名、手机号搜索',
			label: '车辆信息',
			attr: 'keyword',
			type: 'search',
			width: 358,
		},
		{
			placeholder: '请选择费用状态',
			label: '费用状态',
			attr: 'status',
			type: 'select',
		},
		{
			placeholder: '请输入关联账单ID',
			label: '关联账单ID',
			attr: 'tradeBillNo',
			type: 'search',
			width: 200,
		},
		{
			placeholder: '请选择所属公司',
			label: '所属公司',
			attr: 'companyId',
			type: 'select',
			options: [],
		},
		{
			placeholder: '请选择平台渠道',
			label: '平台渠道',
			attr: 'platformChannelId',
			type: 'select',
			options: [],
		},
		{
			placeholder: '请选择费用创建时间',
			label: '费用创建时间',
			attr: 'createTime',
			type: 'date',
		},
	],
}
